import React from "react"

import Footer from "../components/footer"
import Header from "../components/header"

// Stylesheet
import "../sass/main.scss"

const DatenschutzPage = () => (
  <>
    <div className="content">
      <Header />
      <h1>Datenschutzerklärung</h1>
      <br />
      <p>Zuletzt aktualisiert am: 02.08.2024</p>
      <br />
      <p>
        Wir legen grossen Wert darauf, dass der Umgang mit Personendaten
        transparent ist. Diese Datenschutzerklärung gibt Auskunft darüber,
        welche personenbezogenen Daten wir sammeln, zu welchem Zweck und an wen
        wir sie weitergeben. Um eine hohe Transparenz zu gewährleisten, wird
        diese Datenschutzerklärung regelmässig überprüft und aktualisiert.{" "}
      </p>
      <br />
      <h2>1. Welche Dienste wir nutzen</h2>
      <p></p>
      <ul></ul>
      <p></p>
      <br />
      <h2>2. Kontaktinformationen</h2>
      <p>
        Bei Fragen oder Anliegen zum Schutz Ihrer Daten durch uns erreichen Sie
        uns jederzeit per E-Mail unter info@koerpermusik.ch. Verantwortlich für
        die Datenbearbeitungen, die über diese Website erfolgen, ist:
      </p>
      <br />
      <p>
        Karin Enz Gerber
        <br />
        Poststrasse 2<br />
        Liestal 4410
        <br />
        Schweiz
        <br />
        <br />
        <strong>Datenschutzverantwortliche Person:</strong>
        <br />
        Andreas Gerber
        <br />
        info@koerpermusik.ch
        <br />
        079/417 86 87
      </p>
      <br />
      <h2>3. Allgemeine Grundsätze</h2>
      <h3>
        3.1 Welche Daten sammeln wir von Ihnen und von wem erhalten wir diese
        Daten
      </h3>
      <p>
        In erster Linie bearbeiten wir Personendaten, die Sie uns übermitteln
        oder die wir beim Betrieb unserer Website sammeln. Unter Umständen
        erhalten wir Personendaten über Sie auch von Dritten. Das können
        folgende Kategorien sein:
      </p>
      <ul>
        <li>Personenstammdaten (Name, Adresse, Geburtsdaten, etc.);</li>
        <li>Kontaktdaten (Handynummer, E-Mailadresse, etc.);</li>
        <li>Finanzdaten (bspw. Kontoangaben);</li>
        <li>Onlinekennungen (bspw. Cookie-Kennung, IP-Adressen);</li>
        <li>Standort- und Verkehrsdaten;</li>
        <li>Ton- und Bildaufnahmen;</li>
        <li>
          besonders schützenswerte Daten (bspw. biometrische Daten oder Angaben
          über Ihre Gesundheit).
        </li>
      </ul>
      <br />
      <h3>3.2 Unter welchen Voraussetzungen bearbeiten wir Ihre Daten?</h3>
      <p>
        Wir behandeln Ihre Daten vertraulich und gemäss den in dieser
        Datenschutzerklärung festgelegten Zwecken. Wir achten dabei auf eine
        transparente und verhältnismässige Bearbeitung.
        <br />
        <br />
        Falls wir ausnahmsweise nicht in der Lage sind, diese Grundsätze zu
        befolgen, kann die Datenbearbeitung trotzdem rechtmässig sein, weil ein
        Rechtfertigungsgrund vorliegt. Als Rechtfertigungsgrund kommt namentlich
        in Frage:
      </p>
      <ul>
        <li>Ihre Einwilligung;</li>
        <li>
          die Durchführung eines Vertrages oder vorvertraglicher Massnahmen;
        </li>
        <li>
          unsere berechtigten Interessen, sofern Ihre Interessen nicht
          überwiegen.
        </li>
      </ul>
      <br />
      <h3>3.3 Wie können Sie Ihre Einwilligung widerrufen?</h3>
      <p>
        Haben Sie uns eine Einwilligung zur Bearbeitung Ihrer personenbezogenen
        Daten für bestimmte Zwecke erteilt, bearbeiten wir Ihre Daten im Rahmen
        dieser Einwilligung, soweit wir keinen anderen Rechtfertigungsgrund
        haben.
        <br />
        <br />
        Sie haben jederzeit die Möglichkeit, Ihre Einwilligung zu widerrufen
        indem Sie eine E-Mail an die im Impressum genannte Adresse schicken.
        Bereits erfolgte Datenverarbeitungen sind davon nicht betroffen.
      </p>
      <br />
      <h3>
        3.4 In welchen Fällen können wir Ihre Daten anstyle="color:inherit;"
        Dritte weitergeben?
      </h3>
      <br />
      <h4>a. Grundsatz</h4>
      <p>
        Wir sind unter Umständen darauf angewiesen, die Dienste Dritter oder von
        verbundenen Unternehmen in Anspruch zu nehmen und diese mit der
        Verarbeitung Ihrer Daten zu beauftragen (sog. Auftragsverarbeiter).
        Kategorien der Empfänger sind namentlich:
      </p>
      <ul>
        <li>Buchhaltung, Treuhand und Revisionsunternehmen;</li>
        <li>Beratungsunternehmen (Rechtsberatung, Steuern, etc.);</li>
        <li>
          IT-Dienstleister (Webhosting, Support, Clouddienste,
          Webseitengestaltung, etc.);
        </li>
        <li>Zahlungsdienstleister;</li>
        <li>Anbieter von Tracking-, Conversion- und Werbedienstleistungen.</li>
      </ul>
      <br />
      <p>
        Wir stellen sicher, dass diese Dritten und unsere verbundenen
        Unternehmen die Voraussetzungen des Datenschutzes einhalten und Ihre
        personenbezogenen Daten vertraulich behandeln.
        <br />
        <br />
        Unter Umständen sind wir auch verpflichtet, Ihre personenbezogenen Daten
        an Behörden bekanntzugeben.
      </p>
      <br />
      <h4>b. Besuch unserer Social-Media-Kanäle</h4>
      <p>
        Wir haben eventuell auf unserer Website Links zu unseren
        Social-Media-Kanälen eingebettet. Das ist für Sie jeweils ersichtlich
        (typischerweise über entsprechende Symbole). Klicken Sie auf die
        Symbole, werden Sie auf unsere Social-Media-Kanäle weitergeleitet.
        <br />
        <br />
        Die Social Media Anbieter erfahren in diesem Fall, dass Sie von unserer
        Website auf deren Plattform zugreifen. Die Social Media Anbieter können
        die so erhobenen Daten für eigene Zwecke nutzen. Wir weisen darauf hin,
        dass wir keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
        Nutzung durch die Betreiber erhalten.
      </p>
      <br />
      <h4>c. Weitergabe ins Ausland</h4>
      <p>
        Unter Umständen kann es im Rahmen der Auftragsbearbeitung zu
        Übermittlung Ihrer personenbezogenen Daten an Unternehmen im Ausland
        kommen. Diese Unternehmen sind im gleichen Umfang zum Datenschutz
        verpflichtet, wie wir selbst. Die Übermittlung kann weltweit
        stattfinden.
        <br />
        <br />
        Entspricht das Datenschutzniveau nicht demjenigen des der Schweiz, so
        nehmen wir eine vorgängige Risikoeinschätzung vor und stellen
        vertraglich sicher, dass der gleiche Schutz wie in der Schweiz
        garantiert wird (bspw. mittels der neuen Standardvertragsklauseln der
        EU-Kommission oder anderen, gesetzlich vorgegebenen Massnahmen). Sollte
        unsere Risikoeinschätzung negativ ausfallen, ergreifen wir zusätzliche
        technische Massnahmen zum Schutz Ihrer Daten. Sie können die
        Standardvertragsklauseln der EU-Kommission abrufen unter folgendem Link.
        https://commission.europa.eu/publications/standard-contractual-clauses-controllers-and-processors-eueea_de
      </p>
      <br />
      <h3>3.5 Wie lange bewahren wir Ihre Daten auf?</h3>
      <p>
        Wir speichern personenbezogene Daten nur so lange, wie dies erforderlich
        ist, um die einzelnen Zwecke, zu denen die Daten erhoben wurden, zu
        erfüllen.
        <br />
        <br />
        Daten, die wir bei Ihrem Besuch auf unserer Website speichern, werden
        während zwölf Monaten aufbewahrt. Eine Ausnahme gilt für Analyse- und
        Trackingdaten, die länger aufbewahrt werden können.
        <br />
        <br />
        Vertragsdaten speichern wir länger, da wir dazu durch gesetzliche
        Vorschriften verpflichtet sind. Wir müssen insbesondere geschäftliche
        Kommunikation, geschlossene Verträge und Buchungsbelege bis zu 10 Jahren
        aufbewahren. Soweit wir solche Daten von Ihnen nicht mehr zur
        Durchführung der Dienstleistungen benötigen, werden die Daten gesperrt
        und wir verwenden sie nur noch für Zwecke der Rechnungslegung und für
        Steuerzwecke.
      </p>
      <br />
      <h3>3.6 Wie schützen wir Ihre Daten?</h3>
      <p>
        Wir werden Ihre Daten sicher aufbewahren und alle angemessenen
        Massnahmen ergreifen, um Ihre Daten vor Verlust, Zugriff, Missbrauch
        oder Änderungen zu schützen.
        <br />
        <br />
        Unsere Vertragspartner und Mitarbeitende, die Zugang zu Ihren Daten
        haben, sind zur Einhaltung der datenschutzrechtlichen Bestimmungen
        verpflichtet. In manchen Fällen wird es erforderlich sein, dass wir Ihre
        Anfragen an mit uns verbundene Unternehmen weiterreichen. Auch in diesen
        Fällen werden Ihre Daten vertraulich behandelt.
        <br />
        <br />
        Innerhalb unserer Webseite verwenden wir das SSL-Verfahren (Secure
        Socket Layer) in Verbindung mit der jeweils höchsten
        Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird.
      </p>
      <br />
      <h3>3.7 Welche Rechte haben Sie?</h3>
      <br />
      <h4>a. Auskunftsrecht</h4>
      <p>
        Sie können jederzeit eine Auskunft über die von uns über Sie
        gespeicherten Daten verlangen. Wir bitten Sie, Ihr Auskunftsgesuch
        zusammen mit einem Identitätsnachweis an karin-enz@bluemail.ch zu
        senden.
        <br />
        <br />
        Sie haben ausserdem das Recht, Ihre Daten in einem gängigen Dateiformat
        zu erhalten, wenn wir Ihre Daten automatisiert bearbeiten, und wenn:
      </p>
      <ul>
        <li>
          Sie Ihre Einwilligung für die Bearbeitung dieser Daten erteilt haben;
          oder
        </li>
        <li>
          Sie Daten im Zusammenhang mit dem Abschluss oder der Abwicklung eines
          Vertrags bekannt gegeben haben.
        </li>
      </ul>
      <p>
        Wir können die Auskunft bzw. Datenherausgabe einschränken oder
        verweigern, wenn dies unseren gesetzlichen Verpflichtungen, berechtigten
        eigenen oder öffentlichen Interessen oder Interessen einer Drittperson
        entgegensteht.
        <br />
        <br />
        Die Bearbeitung Ihres Gesuchs unterliegt der gesetzlichen
        Bearbeitungsfrist von 30 Tagen. Diese Frist dürfen wir jedoch aufgrund
        von hohem Anfragevolumen, aus rechtlichen oder technischen Gründen oder
        weil wir nähere Angaben von Ihnen benötigen, verlängern. Sie werden über
        die Fristverlängerung rechtzeitig mindestens in Textform informiert.
      </p>
      <br />
      <h4>b. Löschung und Berichtigung</h4>
      <br />
      <p>
        Sie haben jederzeit die Möglichkeit, die Löschung oder Berichtigung
        Ihrer Daten zu verlangen. Wir können das Gesuch abweisen, wenn
        gesetzliche Vorschriften uns zur längeren bzw. unveränderten
        Aufbewahrung verpflichten oder ein Erlaubnistatbestand Ihrem Gesuch
        entgegensteht.
        <br />
        <br />
        Bitte beachten Sie, dass die Ausübung Ihrer Rechte unter Umständen im
        Konflikt mit vertraglichen Abmachungen stehen und entsprechende
        Auswirkungen auf die Vertragsdurchführung haben kann (z.B. vorzeitige
        Vertragsauflösung oder Kostenfolgen).
      </p>
      <br />
      <h4>c. Rechtsweg</h4>
      <p>
        Sind Sie von der Bearbeitung personenbezogener Daten betroffen, haben
        Sie das Recht, Ihre Rechte gerichtlich durchzusetzen oder bei der
        zuständigen Aufsichtsbehörde eine Meldung einzureichen. Die zuständige
        Aufsichtsbehörde in der Schweiz ist der Eidgenössische Datenschutz- und
        Öffentlichkeitsbeauftragte: https://www.edoeb.admin.ch
      </p>
      <br />
      <h3>3.8 Änderungen an der Datenschutzerklärung</h3>
      <p>
        Wir können diese Datenschutzerklärung jederzeit ändern. Die Änderungen
        werden auf www.karinenzgerber.ch veröffentlicht, Sie werden nicht
        gesondert darüber informiert.
      </p>
      <br />
      <h2>4. Einzelne Datenverarbeitungsvorgänge</h2>
      <br />
      <h3>4.1 Bereitstellen der Webseite und Erstellung von Logfiles</h3>
      <br />
      <h4>Welche Informationen erhalten wir und wie nutzen wir sie?</h4>
      <p>
        Indem Sie www.karinenzgerber.ch besuchen, werden auf unseren Servern
        oder auf Servern von Dienstleistungen und Produkten, die wir beziehen
        und / oder installiert haben, bestimmte Daten automatisch zu Zwecken der
        Systemadministration, für statistische oder für Sicherungszwecke oder
        für Trackingzwecke gespeichert. Es handelt sich dabei um:
      </p>
      <ul>
        <li>den Namen Ihres Internetserviceproviders;</li>
        <li>Ihre IP-Adresse (unter Umständen);</li>
        <li>die Version Ihrer Browser-Software;</li>
        <li>
          das Betriebssystem des Rechners mit dem auf die URL zugegriffen wird;
        </li>
        <li>das Datum und die Uhrzeit des Zugriffs;</li>
        <li>die Webseite, von der aus Sie URL besuchen;</li>
        <li>die Suchwörter die Sie benutzt haben, um die URL finden.</li>
      </ul>
      <br />
      <h4>Weshalb dürfen wir diese Daten bearbeiten?</h4>
      <p>
        Diese Daten können keiner bestimmten Person zugeordnet werden und es
        findet keine Zusammenführung dieser Daten mit anderen Datenquellen
        statt. Die Speicherung der Logfiles erfolgt, um die Funktionsfähigkeit
        der Webseite zu garantieren und zur Sicherstellung der Sicherheit
        unserer informationstechnischen Systeme. Hierin besteht unser
        berechtigtes Interesse.
      </p>
      <br />
      <h4>Wie können Sie die Datenerfassung verhindern?</h4>
      <p>
        Die Daten werden nur solange gespeichert, wie dies zur Erreichung des
        Zweckes Ihrer Erhebung notwendig ist. Dementsprechend werden die Daten
        nach Beendigung jeder Sitzung gelöscht. Die Speicherung der Logfiles ist
        für den Betrieb der Webseite zwingend notwendig, Sie haben daher keine
        Möglichkeit dagegen Widerspruch zu erheben.
      </p>
      <br />
      <br />
      <br />
      <h3>BrainBox Generatoren</h3> BrainBox Generatoren ist ein Dienst der{" "}
      <a
        style={{ color: "inherit" }}
        href="https://brainbox.swiss/"
        rel="noreferrer"
        target="_blank"
      >
        BrainBox Solutions GmbH
      </a>
      , um alle datenschutzrelevanten Dienste auf einer Website zu erkennen und
      unter anderem damit bei der Erstellung der Datenschutzerklärung zu helfen.
      Dabei werden keine personenbezogenen Daten erfasst oder verarbeitet.
    </div>
    <Footer />
  </>
)

export default DatenschutzPage
